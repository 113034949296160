import React from 'react';
import Separator from '~/components/Separator';
import { CardCritica } from '../../components/CardCritica';
import { useDevolucaoSaidaNFEForn } from '../../DevolucaoSaidaNFEFornContext';
import { Container } from './styles';

export const Critica: React.FC = () => {
  const { criticas, setCriticas } = useDevolucaoSaidaNFEForn();

  const handleShowAllCriticas = () => {
    const newCriticas = criticas.map((critica) => {
      critica.show = true;
      return critica;
    });
    setCriticas(newCriticas);
  };

  return (
    <Container>
      <Separator labelText="Inconsistências" />
      <span className="show-action" onClick={() => handleShowAllCriticas()}>
        Mostrar Todas
      </span>

      {criticas.map((critica) => {
        return (
          <CardCritica
            key={critica.title}
            type={critica.type}
            title={critica.title}
            message={critica.message}
            show={critica.show}
          />
        );
      })}
    </Container>
  );
};
